<template>
  <div class="legal-page">
    <section>
      <h1 class="text-white text-center">
        Our terms &amp; policies
      </h1>
    </section>
    <div class="container">
      <el-row>
        <el-col
          :xs="24"
          :sm="6"
          :md="6"
          :lg="6"
          :xl="{span: 4, offset: 2}"
        >
          <div class="legal-links-wrapper">
            <ul>
              <li>
                <router-link :to="{ name: 'public-terms' }">
                  Terms & conditions
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'public-privacy' }">
                  Privacy policy
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'public-cookie' }">
                  Cookies policy
                </router-link>
              </li>
            </ul>
          </div>
        </el-col>
        <el-col
          :xs="24"
          :sm="18"
          :md="18"
          :lg="18"
          :xl="18"
        >
          <div class="legal-content-wrapper">
            <router-view />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LegalPage',
}
</script>

<style lang="scss">
.legal-page {
  background-color: $white-color;
  section {
    background-color: $banner-primary-bg-color;
    padding: 35px 0;
    margin-bottom: 20px;
    h1 {
      margin: 0;
      @media (max-width: 485px) {
        font-size: 26px;
      }
    }
  }
  h3 {
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
  .legal-links-wrapper {
    ul {
      list-style: none;
      padding: 0;
      li {
        padding: 10px 0;
        .router-link-exact-active {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
  }
  .legal-content-wrapper {
    margin-top: 26px;
    padding-bottom: 50px;
  }
}
</style>
